// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { account, databases } from './appwriteConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined); // Start with undefined
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const initAuth = async () => {
      try {
        // Attempt to get the current session
        const user = await account.get();

        // Fetch additional user details from your users collection
        const userDetails = await fetchUserDetails(user.$id);

        setCurrentUser(userDetails);
        // Optionally store user data in localStorage
        localStorage.setItem('userData', JSON.stringify(userDetails));
      } catch (error) {
        console.error('No active session found', error);
        setCurrentUser(null); // Explicitly set to null if no session
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  const login = async (email, password) => {
    try {
      // Create a session
      await account.createEmailPasswordSession(email, password);

      // Get user info
      const user = await account.get();

      // Fetch additional user details from your users collection
      const userDetails = await fetchUserDetails(user.$id);

      // Update state and localStorage
      setCurrentUser(userDetails);
      localStorage.setItem('userData', JSON.stringify(userDetails));

      return { success: true };
    } catch (error) {
      console.error('Login error', error);
      return { success: false, message: error.message };
    }
  };

  const logout = async () => {
    try {
      await account.deleteSession('current');
      setCurrentUser(null);
      localStorage.removeItem('userData');
    } catch (error) {
      console.error('Logout error', error);
    }
  };

  const fetchUserDetails = async (userID) => {
    try {
      // Replace 'YOUR_DATABASE_ID' and 'YOUR_COLLECTION_ID' with actual IDs
      const response = await databases.getDocument(
        '671f8e3500379c622b01', // Database ID
        '671f927800158efe9602', // Collection ID
        userID
      );
      return response;
    } catch (error) {
      console.error('Error fetching user details', error);
      return null;
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
