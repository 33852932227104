// src/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate, useLocation  } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  if (currentUser === undefined) {
    // Still checking authentication, don't render anything yet
    return null;
  }

  if (!currentUser) {
    // User is not authenticated, redirect to login page
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  // console.log('current user :', currentUser)
  if(currentUser.userType === 'other'){
     // User is other, redirect to account creation page
     return <Navigate to="/create-rapporteur" replace state={{ from: location }} />;
  }

  // User is authenticated, render the children components
  return children;
};

export default PrivateRoute;
