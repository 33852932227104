import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { databases } from './appwriteConfig';
import {
  Table,
  Card,
  Button,
  Container,
  Spinner,
  Alert,
  Row,
  Col,
  Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faTimes,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Query } from 'appwrite';
import { AuthContext } from './AuthContext';
import './assets/styles/ReportsList.css';

const ReportsList = () => {
    const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const limit = 10;
  const [totalReports, setTotalReports] = useState(0);

  useEffect(() => {
    const fetchReportsAndUsers = async () => {
      setLoading(true);
      try {
        const databaseID = '671f8e3500379c622b01';
        const reportsCollectionID = '671f8e59002651e15476';
        const usersCollectionID = '671f927800158efe9602';
        const offset = (page - 1) * limit;

        let reportsResponse

        console.log(currentUser)

        if(currentUser.userType === 'rapporteur'){
             reportsResponse = await databases.listDocuments(
                databaseID,
                reportsCollectionID,
                [Query.equal('userID', currentUser.userID),
                  Query.limit(limit),
                  Query.offset(offset),
                  Query.orderDesc('submissionDate'),
                ]
              );
        }
        else {
             reportsResponse = await databases.listDocuments(
                databaseID,
                reportsCollectionID,
                [
                  Query.limit(limit),
                  Query.offset(offset),
                  Query.orderDesc('submissionDate'),
                ]
              );
        }

        setReports(reportsResponse.documents);
        setTotalReports(reportsResponse.total);

        const userIDs = [
          ...new Set(reportsResponse.documents.map((report) => report.userID)),
        ];

        const usersResponse = await Promise.all(
          userIDs.map((userID) =>
            databases.getDocument(databaseID, usersCollectionID, userID)
          )
        );

        const usersMap = {};
        usersResponse.forEach((user) => {
          usersMap[user.$id] = user;
        });

        setUsers(usersMap);
      } catch (err) {
        console.error('Error fetching reports or users:', err);
        setError('Failed to fetch reports.');
      } finally {
        setLoading(false);
      }
    };

    fetchReportsAndUsers();
  }, [page]);

  const totalPages = Math.ceil(totalReports / limit);

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'approved':
        return (
          <Badge bg="success" className="status-badge">
            <FontAwesomeIcon icon={faCheck} /> Approved
          </Badge>
        );
      case 'pending':
        return (
          <Badge bg="warning" className="status-badge">
            <FontAwesomeIcon icon={faExclamationTriangle} /> Pending
          </Badge>
        );
      case 'rejected':
        return (
          <Badge bg="danger" className="status-badge">
            <FontAwesomeIcon icon={faTimes} /> Rejected
          </Badge>
        );
      default:
        return <Badge bg="secondary" className="status-badge">Unknown</Badge>;
    }
  };

  return (
    <Container className="mt-5 reports-container">
      <h2 className="text-center mb-4">Submitted Reports</h2>
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <Spinner animation="border" />
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : reports.length === 0 ? (
        <Card className="empty-report-card shadow-sm">
          <Card.Body className="text-center">
            <h5 className="mb-3">No reports submitted yet</h5>
            <Button variant="primary" onClick={() => navigate('/submit-report')}>
              Submit a Report
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <>
          <Table bordered hover responsive className="shadow-sm table-centered">
            <thead className="table-dark">
              <tr>
                <th>#</th>
                <th>Rapporteur Name</th>
                <th>Day</th>
                <th>Submission Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report, index) => {
                const user = users[report.userID];
                const rapporteurName = user
                  ? `${user.firstName} ${user.lastName}`
                  : 'Unknown User';
                return (
                  <tr key={report.$id} className="align-middle">
                    <td>{index + 1 + (page - 1) * limit}</td>
                    <td>{rapporteurName}</td>
                    <td>{report.day}</td>
                    <td>{new Date(report.submissionDate).toLocaleString()}</td>
                    <td>{getStatusBadge(report.status)}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2 action-btn"
                        onClick={() => navigate(`/reports/${report.$id}`)}
                      >
                        <FontAwesomeIcon icon={faEye} /> View
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row className="pagination-controls mt-4">
            <Col className="d-flex justify-content-between align-items-center">
              <Button
                variant="outline-dark"
                onClick={handlePrevPage}
                disabled={page === 1}
                className="btn-rounded"
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Previous
              </Button>
              <span className="fw-bold">
                Page {page} of {totalPages}
              </span>
              <Button
                variant="outline-dark"
                onClick={handleNextPage}
                disabled={page === totalPages}
                className="btn-rounded"
              >
                Next <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ReportsList;
