import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { databases } from './appwriteConfig';
import { Button, Container, Spinner, Alert, Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Query } from 'appwrite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faCheckCircle, faFileAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './assets/styles/Dashboard.css'

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [reportCount, setReportCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchReportCount = async () => {
      if (currentUser) {
        setLoading(true);
        try {
          const databaseID = '671f8e3500379c622b01';
          const collectionID = '671f8e59002651e15476';

          let response;
          if (currentUser.userType === 'admin') {
            response = await databases.listDocuments(databaseID, collectionID);
          } else {
            response = await databases.listDocuments(databaseID, collectionID, [
              Query.equal('userID', currentUser.$id),
            ]);
          }
          setReportCount(response.total);
        } catch (err) {
          console.error('Error fetching report count:', err);
          setError('Failed to fetch report count.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchReportCount();
  }, [currentUser]);

  if (!currentUser) {
    return null;
  }

  return (
    <Container className="mt-5">
      <h1 className="display-4 mb-4 text-center">Welcome, {currentUser.firstName}!</h1>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="shadow-sm border-0 mb-4">
            <Card.Body className="text-center">
              {currentUser.userType === 'admin' ? (
                <>
                  <h2 className="mb-4 text-muted">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-primary me-2" />
                    Admin Dashboard
                  </h2>
                  {loading ? (
                    <Spinner animation="border" />
                  ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                  ) : (
                    <h5>Total Reports Submitted: <strong>{reportCount}</strong></h5>
                  )}
                  <Button
                    variant="outline-primary"
                    className="mt-4 px-4"
                    onClick={() => navigate('/reports')}
                  >
                    <FontAwesomeIcon icon={faClipboard} className="me-2" />
                    View Reports
                  </Button>
                </>
              ) : (
                <>
                  <h2 className="mb-4 text-muted">
                    <FontAwesomeIcon icon={faFileAlt} className="text-primary me-2" />
                    Rapporteur Dashboard
                  </h2>
                  {loading ? (
                    <Spinner animation="border" />
                  ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                  ) : (
                    <h5>Your Submitted Reports: <strong>{reportCount}</strong></h5>
                  )}
                  <div className="d-flex flex-column gap-3 mt-4">
                    <Button
                      variant="outline-success"
                      className="px-4"
                      onClick={() => navigate('/submit-report')}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                      Submit a Report
                    </Button>
                    <Button
                      variant="outline-primary"
                      className="px-4"
                      onClick={() => navigate('/reports')}
                    >
                      <FontAwesomeIcon icon={faClipboard} className="me-2" />
                      View My Reports
                    </Button>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
