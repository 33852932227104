import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import NavbarComponent from './NavbarComponent';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <AuthProvider>
      <div className="app-container">
        <Router>
          <NavbarComponent />
          <AppRoutes />
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
