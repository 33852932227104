import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Login from './Login';
import CreateRapporteur from './CreateRapporteur';
import Dashboard from './Dashboard';
import PrivateRoute from './PrivateRoute';
import SubmitReport from './SubmitReport';
import ReportsList from './ReportsList';
import ReportDetails from './ReportDetails';
// Import any other components or routes you need

const AppRoutes = () => (
    <RouterRoutes>
        <Route path="/login" element={<Login />} />
        <Route
            path="/create-rapporteur"
            element={
                // <PrivateRoute>
                    <CreateRapporteur />
                // </PrivateRoute>
            }
        />
        <Route
            path="/dashboard"
            element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            }
        />
        <Route
            path="/"
            element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            }
        />
        <Route
            path="/submit-report"
            element={
                <PrivateRoute>
                    <SubmitReport />
                </PrivateRoute>
            }
        />
        <Route
            path="/reports"
            element={
                <PrivateRoute>
                    <ReportsList />
                </PrivateRoute>
            }
        />
        <Route
            path="/reports/:reportId"
            element={
                <PrivateRoute adminOnly={true}>
                    <ReportDetails />
                </PrivateRoute>
            }
        />
        {/* Add other routes here */}
    </RouterRoutes>
);

export default AppRoutes;
