import React, { useState } from 'react';
import {
  Form,
  Button,
  Container,
  Alert,
  Spinner,
  Card,
} from 'react-bootstrap';
import {  useNavigate } from 'react-router-dom';
import { account, databases, Permission, Role } from './appwriteConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faKey, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import './assets/styles/CreateRapporteur.css';

const CreateRapporteur = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userType: 'rapporteur',
    firstName: '',
    lastName: '',
    otherName: '',
    email: '',
    phone: '',
    gender: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const userID = `user_${Math.random().toString(36).substr(2, 9)}`;
      await account.create(
        userID,
        formData.email,
        formData.password,
        `${formData.firstName} ${formData.lastName}`
      );

      const documentData = {
        userID: userID,
        userType: formData.userType,
        firstName: formData.firstName,
        lastName: formData.lastName,
        otherName: formData.otherName,
        email: formData.email,
        phone: formData.phone,
        gender: formData.gender,
        password: formData.password,
      };

      const permissions = [
        Permission.read(Role.any()),
        Permission.update(Role.user(userID)),
        Permission.delete(Role.user(userID)),
      ];

      await databases.createDocument(
        '671f8e3500379c622b01',
        '671f927800158efe9602',
        userID,
        documentData
      );

      setSuccess('Rapporteur account created successfully!');
      setFormData({
        userType: 'rapporteur',
        firstName: '',
        lastName: '',
        otherName: '',
        email: '',
        phone: '',
        gender: '',
        password: '',
      });

      navigate('/login')
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Card className="shadow p-4 w-100" style={{ maxWidth: '500px', borderRadius: '10px' }}>
        <Card.Body>
          <h2 className="text-center mb-4">Create Rapporteur Account</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={handleSubmit} className="mt-4">
            <Form.Group controlId="formFirstName" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faUser} className="me-2 text-primary" /> First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formLastName" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faUser} className="me-2 text-primary" /> Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formOtherName" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faUser} className="me-2 text-primary" /> Other Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter other name"
                name="otherName"
                value={formData.otherName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faEnvelope} className="me-2 text-primary" /> Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faPhone} className="me-2 text-primary" /> Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g., +256..."
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formGender" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faVenusMars} className="me-2 text-primary" /> Gender</Form.Label>
              <Form.Select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label><FontAwesomeIcon icon={faKey} className="me-2 text-primary" /> Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Minimum 6 characters"
                name="password"
                value={formData.password}
                onChange={handleChange}
                minLength="6"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading} className="w-100 mt-3">
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Creating...
                </>
              ) : (
                'Create Account'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateRapporteur;
