// src/appwriteConfig.js
import { Client, Account, Databases, Permission, Role, Storage } from 'appwrite';

const client = new Client();

client
    .setEndpoint('https://appwrite.nrep.ug/v1') // Your Appwrite Endpoint
    .setProject('671f8799002cc71e4f5e');               // Your project ID

const account = new Account(client);
const databases = new Databases(client);
const storage = new Storage(client);

export { client, account, databases, storage, Permission, Role };
