import React, { useContext, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { AuthContext } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { account } from './appwriteConfig';

const NavbarComponent = () => {
  const { currentUser, logout  } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      // Remove navigate('/login') from here
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Use useEffect to navigate when currentUser becomes null
  useEffect(() => {
    if (currentUser === null) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
        <img src="/images/NREP.webp" alt="Logo" className="navbar-logo me-2" style={{ width: '50px', height: 'auto' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {currentUser && currentUser.userType === 'admin' && currentUser.userType !== 'other' && (
              <>
                <Nav.Link as={Link} to="/dashboard">
                  Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/reports">
                  Reports
                </Nav.Link>
                {/* Add more admin-specific links here */}
              </>
            )}
            {currentUser && currentUser.userType !== 'admin' && currentUser.userType !== 'other' && (
              <>
                <Nav.Link as={Link} to="/dashboard">
                  Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/submit-report">
                  Submit Report
                </Nav.Link>
                <Nav.Link as={Link} to="/reports">
                  My Reports
                </Nav.Link>
                {/* Add more rapporteur-specific links here */}
              </>
            )}
          </Nav>
          <Nav>
            {currentUser ? (
              <NavDropdown title={currentUser.firstName} id="basic-nav-dropdown">
                {/* <NavDropdown.Item as={Link} to="/profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/login">
                  Login
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/signup">
                  Sign Up
                </Nav.Link> */}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
