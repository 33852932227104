import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { databases, storage, client } from './appwriteConfig';
import { Container, Spinner, Alert, Button, ButtonGroup, Form, Badge, Card, Row, Col, Modal } from 'react-bootstrap';
import { AuthContext } from './AuthContext';
import { Permission, Role } from 'appwrite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheck, faTimes, faExclamationTriangle, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const ReportDetails = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [report, setReport] = useState(null);
  const [rapporteur, setRapporteur] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleted, setDeleted] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  // For admin update
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);

  // For rapporteur file upload
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(null);

  // Variables
  const bucketID = '671f943600295ea0597c';
  const databaseID = '671f8e3500379c622b01';
  const reportsCollectionID = '671f8e59002651e15476';
  const usersCollectionID = '671f927800158efe9602';

  useEffect(() => {
    const fetchReportAndUser = async () => {
      if (!currentUser) return;
      setLoading(true);
      try {
        // Fetch report details
        const reportResponse = await databases.getDocument(
          databaseID,
          reportsCollectionID,
          reportId
        );

        if (currentUser.userType !== 'admin' && reportResponse.userID !== currentUser.$id) {
          setError('You do not have permission to view this report.');
          return;
        }

        setReport(reportResponse);
        setStatus(reportResponse.status);
        setComment(reportResponse.comment || '');

        // Fetch rapporteur details
        const userResponse = await databases.getDocument(
          databaseID,
          usersCollectionID,
          reportResponse.userID
        );
        setRapporteur(userResponse);
      } catch (err) {
        console.error('Error fetching report or user:', err);
        setError(`Failed to fetch report details: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchReportAndUser();
  }, [reportId, currentUser]);

  const handleDownload = async () => {
    try {
      const fileID = report.report;
      const projectID = client.config.project;
      const endpoint = client.config.endpoint;
      const fileName = 'report_' + report.$id;

      const downloadURL = `${endpoint}/storage/buckets/${bucketID}/files/${fileID}/download?project=${projectID}`;

      const response = await fetch(downloadURL, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Error fetching file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert(`Error downloading file: ${error.message}`);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setUpdateError(null);
    setUpdateSuccess(null);

    try {
      const updateData = {
        status,
        comment,
        updatedAt: new Date().toISOString(),
      };

      await databases.updateDocument(
        databaseID,
        reportsCollectionID,
        report.$id,
        updateData
      );

      setUpdateSuccess('Report updated successfully!');
      setReport((prevReport) => ({
        ...prevReport,
        ...updateData,
      }));
    } catch (err) {
      console.error('Error updating report:', err);
      setUpdateError(`Failed to update report details: ${err.message}`);
    } finally {
      setUpdating(false);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setUploading(true);
    setUploadError(null);
    setUploadSuccess(null);

    const file = e.target.elements.fileInput.files[0];

    if (!file) {
      setUploadError('Please select a file to upload.');
      setUploading(false);
      return;
    }

    try {

      const fileResponse = await storage.createFile(bucketID, 'unique()', file, [
        Permission.read(Role.user(currentUser.$id)),
        Permission.delete(Role.user(currentUser.$id)),
      ]);

      await storage.deleteFile(bucketID, report.report);

      const updatedReport = await databases.updateDocument(
        databaseID,
        reportsCollectionID,
        report.$id,
        {
          report: fileResponse.$id,
          status: 'pending',
          updatedAt: new Date().toISOString(),
        }
      );

      setReport(updatedReport);
      setUploadSuccess('Report updated successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError(`Error uploading file: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const deleteReport = async () => {
    try {
      setDeleting(true)
      setDeleted(true)

      // Delete from Database Table
      await databases.deleteDocument(
        databaseID,
        reportsCollectionID,
        report.$id
      );

      // Delete from Bucket
      await storage.deleteFile(bucketID, report.report);

      //Navigate to list
      navigate('/reports')

    } catch (err) {
      setDeleted(false)
      console.error('Failed to delete file: ', err)
    } finally {
      setDeleting(false)
    }
  }

  const handleForgotPasswordClick = () => setShowModal(true); // Show modal when clicked

  if (loading) {
    return (
      <Container className="mt-5 d-flex justify-content-center">
        <Spinner animation="border" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!report) {
    return (
      <Container className="mt-5">
        <Alert variant="warning">No report data available.</Alert>
      </Container>
    );
  }

  const getStatusBadge = (status) => {
    switch (status) {
      case 'approved':
        return <Badge bg="success"><FontAwesomeIcon icon={faCheck} /> Approved</Badge>;
      case 'pending':
        return <Badge bg="warning"><FontAwesomeIcon icon={faExclamationTriangle} /> Pending</Badge>;
      case 'rejected':
        return <Badge bg="danger"><FontAwesomeIcon icon={faTimes} /> Rejected</Badge>;
      default:
        return <Badge bg="secondary">Unknown</Badge>;
    }
  };

  return (

    <Container className="mt-5">
      {
        deleting ?
        <Container className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" />
        </Container>
          :
          <>
            <Card className="shadow-sm p-4">
              <Row>
                <Col md={8}>
                  <h3>Report Details</h3>
                  <p><strong>Rapporteur:</strong> {rapporteur ? `${rapporteur.firstName} ${rapporteur.lastName}` : 'Unknown'}</p>
                  <p><strong>Day:</strong> {report.day}</p>
                  <p><strong>Submission Date:</strong> {new Date(report.submissionDate).toLocaleString()}</p>
                  <p><strong>Status:</strong> {getStatusBadge(report.status)}</p>
                  {report.updatedAt && <p><strong>Last Updated:</strong> {new Date(report.updatedAt).toLocaleString()}</p>}
                  {report.comment && <p><strong>Comment:</strong> {report.comment}</p>}
                </Col>
                <Col md={4} className="d-flex flex-column align-items-end">
                  <Button variant="primary" onClick={handleDownload} className="mb-3">
                    <FontAwesomeIcon icon={faDownload} /> Download Report
                  </Button>
                  {
                    currentUser.userType == 'rapporteur' &&
                    <Button variant="danger" onClick={() => setShowModal(true)} className="mb-3">
                      <FontAwesomeIcon icon={faTrashCan} /> Delete Report
                    </Button>
                  }
                </Col>
              </Row>

              {currentUser.userType === 'admin' && (
                <div className="mt-4">
                  <h4>Update Report Status</h4>
                  {updateError && <Alert variant="danger">{updateError}</Alert>}
                  {updateSuccess && <Alert variant="success">{updateSuccess}</Alert>}
                  <Form onSubmit={handleUpdate}>
                    <Form.Group controlId="formStatus" className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select name="status" value={status} onChange={(e) => setStatus(e.target.value)} required>
                        <option value="">Select status</option>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group controlId="formComment" className="mb-3">
                      <Form.Label>Comment</Form.Label>
                      <Form.Control as="textarea" rows={3} name="comment" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={updating}>
                      {updating ? 'Updating...' : 'Update Report'}
                    </Button>
                  </Form>
                </div>
              )}

              {currentUser.userType !== 'admin' && (
                <div className="mt-4">
                  <h4>Replace Report</h4>
                  {uploadError && <Alert variant="danger">{uploadError}</Alert>}
                  {uploadSuccess && <Alert variant="success">{uploadSuccess}</Alert>}
                  <Form onSubmit={handleFileUpload}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Select a new report file to upload:</Form.Label>
                      <Form.Control type="file" name="fileInput" accept=".pdf,.doc,.docx" />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={uploading}>
                      {uploading ? 'Uploading...' : 'Upload New Report'}
                    </Button>
                  </Form>
                </div>
              )}
            </Card>
          </>
      }

      {/* Delete Report Modal */}
      <Modal show={showModal} backdrop="static" onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !deleted ?
              <p>
                Are you sure you want to <strong>DELETE</strong> the report?
              </p>
              :
              <p>
                Report has successfully been <strong>DELETE</strong>
              </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup hidden={deleting || deleted}>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={deleteReport}>
              Delete
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ReportDetails;
