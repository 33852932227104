// src/SubmitReport.js
import React, { useState, useContext } from 'react';
import {
    Form,
    Button,
    Container,
    Alert,
    Spinner,
} from 'react-bootstrap';
import { Storage, Databases, ID, Permission, Role } from 'appwrite';
import { client } from './appwriteConfig';
import { AuthContext } from './AuthContext';
import moment from 'moment-timezone';

const SubmitReport = () => {
    const { currentUser } = useContext(AuthContext);

    const [formData, setFormData] = useState({
        day: '',
        reportFile: null,
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const storage = new Storage(client);
    const databases = new Databases(client);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, reportFile: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        try {
            if (!currentUser) {
                throw new Error('User not authenticated');
            }

            const userID = currentUser.userID;
            const timezone = 'Africa/Nairobi';
            const submissionDate = moment().tz(timezone).format();

            // 1. Upload the file to Appwrite Storage Bucket
            const bucketID = '671f943600295ea0597c'; // Replace with your actual bucket ID
            const fileID = ID.unique(); // Generates a unique ID
            const response = await storage.createFile(
                bucketID,
                fileID,
                formData.reportFile
            );

            // 2. Save submission details to the database
            const databaseID = '671f8e3500379c622b01'; // Replace with your actual database ID
            const collectionID = '671f8e59002651e15476'; // Replace with your collection ID

            const documentData = {
                userID: userID,
                rapporteurName: `${currentUser.firstName} ${currentUser.lastName}`,
                day: formData.day,
                submissionDate: submissionDate,
                report: response.$id, // Store the file ID
                status: 'pending',
                comment: '',
                updatedAt: submissionDate,
            };

            // Optional: Set permissions
            const permissions = [
                Permission.read(Role.user(userID)),
                Permission.update(Role.user(userID)),
                Permission.delete(Role.user(userID)),
            ];

            await databases.createDocument(
                databaseID,
                collectionID,
                response.$id, // ID.unique(), // Document ID
                documentData,
                //permissions
            );

            setSuccess('Report submitted successfully!');
            setFormData({
                day: '',
                reportFile: null,
            });
        } catch (err) {
            console.error('Error submitting report:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <h1 className="mt-5">Submit Daily Report</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form onSubmit={handleSubmit} className="mt-4">
                <Form.Group controlId="formDay" className="mb-3">
                    <Form.Label>Select Day</Form.Label>
                    <Form.Select
                        name="day"
                        value={formData.day}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select day</option>
                        <option value="day_1">Day 1</option>
                        <option value="day_2">Day 2</option>
                        <option value="day_3">Day 3</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group controlId="formReportFile" className="mb-3">
                    <Form.Label>Upload Report</Form.Label>
                    <Form.Control
                        type="file"
                        name="reportFile"
                        onChange={handleFileChange}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{' '}
                            Submitting...
                        </>
                    ) : (
                        'Submit Report'
                    )}
                </Button>
            </Form>
        </Container>
    );
};

export default SubmitReport;
