import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Container, Alert, Spinner, Card, Modal } from 'react-bootstrap';
import { AuthContext } from './AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import './assets/styles/Login.css';

const Login = () => {
  const { login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  useEffect(() => {
    const clearSession = async () => {
      await logout();
    };
    clearSession();
  }, [logout]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const result = await login(formData.email, formData.password);

    if (!result.success) {
      setError(result.message);
    } else {
      const from = location.state?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    }

    setLoading(false);
  };

  const handleForgotPasswordClick = () => setShowModal(true); // Show modal when clicked

  return (
    <>
      <Container className="d-flex align-items-center justify-content-center min-vh-100">
        <Card className="p-4 shadow-sm w-100" style={{ maxWidth: '400px', borderRadius: '10px' }}>
          <Card.Body>
            <h2 className="text-center mb-4">Sign In</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2 text-primary" />
                  Email Address
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faLock} className="me-2 text-primary" />
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={loading} className="w-100 mt-3">
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Logging in...
                  </>
                ) : (
                  'Login'
                )}
              </Button>
            </Form>

            {/* Forgot Password Link */}
            <p className="text-center mt-3">
              <a href="#forgot" onClick={handleForgotPasswordClick} className="text-decoration-none text-muted">
                Forgot Password?
              </a>
            </p>
          </Card.Body>
        </Card>
      </Container>

      {/* Forgot Password Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please contact the <strong>NREP Principal Systems Lead</strong> to assist you with resetting your password.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
